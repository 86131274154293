@import "bootstrap-variables";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../icons";

@import "tinySlider";

@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600,700&display=swap');

body {
	font-size: 18px;

	@include media-breakpoint-up(lg) {
		font-size: 20px;
		.page-link {
			font-size: 20px;
		}
		.lead {
			font-size: 23px;
		}
	}
}

a {
	text-decoration: none;
}

header {
	margin-bottom: 40px;
}

.header-top {
	display: flex;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;

	@include media-breakpoint-down(lg){
		display: none;
	}

	.theme-switch {
		margin-left: auto;
	}

	.follow-us {
		display: flex;
		align-items: center;
		height: 50px;
		gap: 5px;

		a {
			line-height: 50px;
			width: 32px;
			text-align: center;
			color: $gray-800;
			font-size: 26px;

			&:hover {
				&.facebook {
					color: #4267B2;
				}

				&.instagram {
					color: #833ab4;
				}
			}
		}
	}
}
.d-dark {
    display: none;
}
@include color-mode('dark'){
    .d-dark {
        display: block;
    }
    .d-light {
        display: none;
    }
    .header-top .follow-us a {
        color: #fff;
    }
}
button {
	outline: 0 !important;
}
@include media-breakpoint-down(lg){
	header {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 10000;
	}
	main {
		padding-top: 82px;
	}
}
header > .navbar {
	padding: 0;
	background: var(--bs-gray-800);
	box-shadow: inset 0 -6px 0 rgba(0, 0, 0, 0.37);

	.mobile-menu, .mobile-search {
		display: none;
	}

	@include media-breakpoint-down(lg){
		background: var(--bs-primary);
		box-shadow: none;

		.container {
			max-width: 100% !important;
			padding: 0 15px;
			display: flex;
		}

		.mobile-search {
			display: block;
		}

		.mobile-menu {
			display: block;
			background: var(--bs-gray-800);
			box-shadow: inset 0 -6px 0 rgba(0, 0, 0, 0.37);

			.menu-navbar {
				margin: 0;
				padding: 40px 20px 30px 20px;
				gap: 15px;
			}
			.nav-link {
				background: var(--bs-primary);

				&:hover, &:focus, &:active {
					color: var(--bs-gray-800)
				}
			}
		}
		.post-groups {
			background: var(--bs-primary);

		}
	}

	.container {
		padding: 0;
	}

	.navbar-nav {
		.nav-link {
			color: #f0f0f0;
			line-height: 28px;
			padding: 16px 15px !important;

			&:hover, &:active {
                background: var(--bs-gray-800);
                color: #f0f0f0;
			}
		}
	}

	.navbar-brand {
		margin-top: -5px;
		padding: 0;

		@include media-breakpoint-down(lg) {
			position: absolute;
			left: 50%;
			top: 0;
			background: var(--bs-primary);
			display: block;
			transform: translate(-50%, 0);
			border-style: solid;
			border-color: var(--bs-primary);
			border-radius: 10px;
			border-width:  10px 10px 1px 10px;

			svg {
				.bottom-bar,
				.header-logo-bg {
					display: none;
				}
				.header-logo-text {
					fill: #fff !important;
				}
			}
		}
	}
	.navbar-toggler {
		border: 0 none !important;
		border-radius: 0;
		color: #fff;
		font-size: 22px;
		padding: 10px 14px;
		line-height: 26px;
		outline: 0 !important;
		box-shadow: none !important;

		&:hover, &:focus, &:active {
			color: var(--bs-gray-800)
		}
	}
}

.header-bottom {
	background: var(--bs-primary);
	color: #fff;

	.container {
		min-height: 78px;
	}

	.navbar {
		padding: 10px 0;

		.nav-link {
			padding: 15px;
			color: var(--bs-white);

			&:hover, &:active {
				color: var(--bs-gray-800);
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.header-bottom-desktop {
		display: none;
	}
}


footer {
	background: var(--bs-gray-800);
	color: $white;
	margin-top: 40px;

	> .container:first-child {
		padding: 15px;
	}
	.partners {
		background: #1F2224;
		padding: 15px 0;
	}

	.partner-list {
		display: flex;
		gap: 15px;
		justify-content: center;
		flex-wrap: wrap;

		a {
			display: block;
			overflow:hidden;
			border-radius: 5px;
		}
	}

	@include media-breakpoint-down(lg){
		text-align: center;
	}
}

.footer-menu {
	padding: 0;

	.navbar-nav {
		flex-direction: row;
		gap: 15px;
		flex-wrap: wrap;

		@include media-breakpoint-down(lg){
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;
			width: 100%;
		}
	}

	.nav-link {
		color: $white;
		padding: 0;

		&:hover, &:active {
			color: var(--bs-primary);
		}
	}
}

.section-title {
	font-size: 47px;
	line-height: 67px;
	padding: 0 0 0 25px;
	margin-bottom: 30px;
	position: relative;
	border-bottom: 3px solid #d1d1d1;
	text-transform: uppercase;
	text-align: center;

	i {
		color: var(--bs-primary);
	}

	&:after {
		display: block;
		position: absolute;
		left: 0;
		top: 3px;
		bottom: 3px;
		content: '';
		width: 9px;
		background: var(--bs-primary);
	}

	&.main-section-title {
		&:after {
			display: none;
		}
	}

    @include media-breakpoint-down(md){
        font-size: 30px;
        line-height: 37px;
    }
}
.main-page-section {
	margin-bottom: 40px;
}
.tag-section-title {
	border-left: 5px solid var(--bs-primary);
	padding: 1px 0 1px 10px;
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 30px;

	a {
		color: var(--bs-body-color);

		&:hover, &:focus, &:active {
			color: var(--bs-primary)
		}
	}
}

.gallery-section-title {
	font-size: 47px;
	line-height: 67px;
	margin-bottom: 30px;
	position: relative;
	border-bottom: 3px solid #d1d1d1;
	text-transform: uppercase;
	text-align: center;

    @include media-breakpoint-down(md){
        font-size: 27px;
        line-height: 35px;
    }
}

main {
	min-height: 400px;
}

.keyword {
	font-weight: 300;
	display: block;

	span {
		color: var(--bs-primary);
	}
}

article.post {
	margin-bottom: 10px;

	.keyword {
		font-size: 16px;
		margin-bottom: 5px;
	}

	img {
		width: 100%;
	}

	&.color {
		h2 strong {
			padding: 2px 8px;
			-webkit-box-decoration-break: clone;
			box-decoration-break: clone;
			line-height: 1.4;
		}
	}

	.event-date {
		font-size: 14px;
		margin-bottom: 10px;
	}

	.post-content {
		position: relative;

		> a {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}

		&:hover, &:focus {
			h2 {
				color: var(--bs-primary)
			}
		}
	}

	&.color {
		.post-content {
			&:hover, &:focus {
				h2 strong {
					color: var(--bs-white) !important;
					background: var(--bs-body-color) !important;
				}
			}
		}
	}

	.keyword {
		font-size: 16px;
		margin-bottom: 5px;
	}


	p {
		font-size: 18px;
		margin: 0;
		line-height: 1.6;

		i {
			color: var(--bs-primary);
		}
	}

	h2 {
		font-size: 22px;
		margin: 0 0 10px;
		color: var(--bs-gray-800);

		@include media-breakpoint-down(sm) {
			font-size: 18px;
		}
		@include media-breakpoint-only(md) {
			font-size: 26px;
		}
	}

	.lead {
		margin-bottom: 10px;
	}

	.img {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		border-radius: 10px;
	}

	.created-by {
		padding: 5px 0;
		border-bottom: 1px dashed #c4c3c3;
		border-top: 1px dashed #c4c3c3;
		font-size: 16px;
		display: flex;
		align-items: center;

		svg {
			width: 80px;
			height: auto;
			margin: -5px 0;
		}

		a {
			text-decoration: none;
			color: var(--bs-body-color);

			&:hover, &:focus {
				color: var(--bs-primary)
			}
		}
	}

	&.post-featured {
		margin-bottom: 30px;

		h2 {
			font-size: 37px;
		}

		p {
			font-size: 23px;
		}

		.lead {
			padding: 10px;
		}
	}

	&.post-featured1 {
		h2 {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
		}
	}

	&.post-featured2 .post-content {
		border: 6px solid #fff;
		padding: 10px;
		background: var(--bs-white);
		margin-bottom: 15px;

		&:hover, &:focus {
			border-color: var(--bs-body-color) !important;
		}

		.lead {
			margin: 0;
		}
	}

	&.post-featured3 {
		h2 {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			padding: 10px;
			color: var(--bs-white);
		}
	}

	&.post-featured4 {
		.lead {
			padding: 0 20px;
			margin-top: -60px;
			position: relative;
		}

		h2 {
			background: var(--bs-primary);
			padding: 15px;
			color: #fff;
			margin: 0 0 10px;
		}

		.post-content {
			&:hover, &:focus {
				h2 {
					color: var(--bs-white) !important;
					background: var(--bs-body-color) !important;
				}
			}
		}
	}

	&.post-featured5 {
		h2 {
			position: relative;
			color: var(--bs-body-color) !important;

			i {
				color: var(--bs-primary)
			}
		}

		.post-content {
			border-left: 15px solid var(--bs-primary);
			padding: 0 10px;
		}

		.post-content {
			&:hover, &:focus {
				border-color: var(--bs-body-color);

				h2 {
					i {
						color: var(--bs-body-color) !important;
					}
				}
			}
		}
	}
}
@include color-mode('dark'){
	article.post {
		h2 {
			color: #fff;
		}
		&.color {
			.post-content {
				&:hover, &:focus {
					h2 strong {
						color: $body-color !important;
						background: var(--bs-body-color) !important;
					}
				}
			}
		}
		&.post-featured4 {
			.post-content {
				&:hover, &:focus {
					h2 {
						color: $body-color !important;
						background: var(--bs-body-color) !important;


					}
				}
			}
		}
	}
}
.box {
	margin: 50px 0 20px;
	padding: 20px;
	background: url(/pics/box-bg.png) 0 0 repeat;
	box-shadow: 7px 7px 0 #e0e0e0;
	position: relative;
	border-radius: 10px;

	ul {
		margin: 0 0 0;
		padding: 0;
		list-style: none;
	}

	li {
		margin-bottom: 10px;
		text-align: center;
		font-size: 19px;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 50%;
			margin: 0 auto 18px;
			border-bottom: 1px dashed #c4c3c3;
			text-align: center;
			height: 8px;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, 0);
			width: 16px;
			height: 16px;
			border-radius: 16px;
			background: $gray-800;
		}

		&:first-child {
			&:before,
			&:after {
				display: none !important;
			}
		}

		a {
			color: $gray-800;
			text-decoration: none;
			display: block;

			&:hover, &:focus {
				color: var(--bs-primary)
			}
		}
		.info  {
			font-weight: 300;
			display: flex;
			justify-content: center;
			gap: 8px;
			align-items: center;
		}

		.img {
			margin-bottom: 10px;
			border-radius: 10px;
			overflow: hidden;
		}

		.date {
			text-transform: capitalize;
			font-size: 14px;
		}
	}
}

@include color-mode(dark, true) {
    .form-control {
        background: #1F2224;
        border-color: var(--bs-body-bg)
    }
    .question {
        background: #1F2224;
    }
	.box {
		margin: 50px 0 20px;
		padding: 20px;
		background: url(/pics/box-bg-dark.png) 0 0 repeat;
		box-shadow: 7px 7px 0 rgba(0,0,0,.4);

		.box-content {
			background: #313539;
			color: #fff;
			border: 1px solid #1f2224;
		}

		li {

			&:before {
				content: '';
				display: block;
				width: 50%;
				margin: 0 auto 18px;
				border-bottom: 1px dashed rgba(255,255,255,.3);
				text-align: center;
				height: 8px;
			}

			&:after {
				background: #fff;
			}

			a {
				color: $body-color-dark;
				text-decoration: none;
				display: block;

				&:hover, &:focus {
					color: var(--bs-primary)
				}
			}
		}
	}
	.embedded-article {
		background: #222;
	}
	.related-posts {
		background: #222;
	}
}

.box-content {
	background: #fff;
	border: 1px solid #f0f0f0;
	padding: 45px 12px 0;
}

.box-title {
	height: 70px;
	border-radius: 5px;
	background: #3888ff;
	position: absolute;
	left: 50%;
	line-height: 65px;
	text-align: center;
	color: #fff;
	text-shadow: 0 4px 0 #2356a1;
	white-space: nowrap;
	min-width: 70px;
	width: auto;
	margin: 0;
	transform: translate(-50%, 0);
	font-size: 25px;
	top: -25px;
	font-weight: normal;
	padding: 0 20px;
	text-transform: uppercase;
}

.box-btn {
	display: block;
	padding: 12px 16px;
	font-size: 20px;
	margin: 0 -12px;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	color: var(--bs-body-color);

	&:hover, &:focus {
		background: var(--bs-primary);
		color: var(--bs-white);
	}
}

.more-posts {
	margin-top: 30px;
	display: flex;

	a {
		display: block;
		border-left: 4px solid var(--bs-primary);
		padding-left: 10px;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: bold;
		text-decoration: none;
		color: var(--bs-body-color);

		&:hover, &:focus {
			color: var(--bs-primary);
		}
	}
}

.gallery-slider {
	article {
		position: relative;

		a {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}

		.img {
			position: relative;

			img {
				width: 100%;
				filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
				filter: gray;
				-webkit-filter: grayscale(100%);
			}
		}

		h2 {
			margin: 0;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			text-align: center;
			padding: 0 10%;
			font-size: 24px;
			color: #4d4d4d;

			@include media-breakpoint-up(lg) {
				font-size: 37px;
			}

			strong {
				padding: 2px 8px;
				-webkit-box-decoration-break: clone;
				box-decoration-break: clone;
				line-height: 1.4;
				background: var(--bs-black);
				color: var(--bs-white);
			}
		}

		.details {
			display: block;
			text-align: center;
			font-weight: 300;
			font-size: 23px;
			color: var(--bs-body-color);
			visibility: hidden;
			padding-top: 5px;
		}

		&:hover, &:focus {
			h2 strong {
				background-color: var(--bs-primary);
			}
		}
	}

	.active-slide {
		article {
			.details {
				visibility: visible;
			}
		}
	}

	.active-slide article,
	article:hover,
	article:focus {
		.img {
			img {
				filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
				-webkit-filter: grayscale(0%);
			}
		}
	}
}

.post-gallery {
	.img {
		position: relative;

		.icon {
			display: none;
			font-size: 20px;
			padding: 12px 20px;
			justify-content: center;
			align-items: center;
			background: rgba(0, 0, 0, 0.8);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			text-align: center;
			font-weight: 300;

			i {
				margin-right: 10px;
			}
		}
	}

	h2 {
		text-align: center;
	}

	.details {
		color: $gray-800;
		text-align: center;
		font-size: 14px;
		font-weight: 300;
	}

	&:hover {
		.img {
			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				border: 3px solid var(--bs-primary);
			}

			.icon {
				display: flex;
			}

		}

		h2 {
			color: var(--bs-body-color) !important;
		}
	}
}

.tns-outer {
	position: relative;
}

.tns-controls {
	button {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 50%;
		width: 70px;
		height: 70px;
		margin-top: -35px;
		z-index: 10;
		cursor: pointer;
		background: #000;
		background: rgba(0, 0, 0, 0.8);
		color: #fff;
		border: 0 none;

		i {
			font-size: 300%;
		}

		&:first-child {
			left: 15%;
		}

		&:last-child {
			right: 15%;
		}

		&:hover, &:focus, &:active {
			background: var(--bs-primary);
		}
	}
}

ul.pagination {
	margin-top: 40px;
	justify-content: center;
}


article section {
	margin-bottom: 30px;

	@include media-breakpoint-up(xl){
		margin-bottom: 40px;
	}

	&.social-section {
		display: flex;
		justify-content: center;
	}
}


@keyframes loader-rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading {
	position: relative;
	min-height: 100px;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 10005;
		margin: -10px;
		background: rgba(0, 0, 0, .2);
		cursor: wait;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 10006;
		width: 48px;
		height: 48px;
		border: 5px solid #FFF;
		border-bottom-color: var(--bs-primary);
		border-radius: 50%;
		box-sizing: border-box;
		animation: loader-rotation 1s linear infinite;
		margin: -24px 0 0 -24px;
		cursor: wait;
	}

	&.loading--light {
		&:before {
			background: rgba(255, 255, 255, .5);
			box-shadow: 0 0 15px #fff;
		}
	}
}


.embedded-article {
	margin-bottom: 30px;
	margin-top: 30px;
	padding: 15px;
	display: flex;
	flex-direction: row;
	background: #fff;

	.img {
		width: calc(40% + 30px);
		padding-right: 30px;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.details {
		font-size: 14px;

		p {
			margin: 0;
		}

		h3 {
			font-size: 16px;
			margin: 0 0 10px;
		}
	}
}

.banner-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.banner {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;

	&.banner-bg {
		background: #fff;
		padding: 10px;

	}

	> a {
		display: block;
	}

	small {
		font-size: 12px;
		text-transform: uppercase;
		color: #b7b7b7;
	}
}


@include color-mode('dark'){
	.banner {
		&.banner-bg {
			background: #1F2224;
		}

	}
}

article.post-details {
	.cover {
		margin-bottom: 20px;

		img {
			width: 100%;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 60px;
		}
	}

	.author {
		padding-bottom: 10px;
		display: flex;
		color: var(--bs-primary);
		font-size: 22px;
		position: relative;
		line-height: 30px;
		border-bottom: 1px solid #a2a3a3;
		margin-bottom: 10px;
		align-items: center;

		img {
			width: 50px;
			margin-right: 15px;
			border-radius: 10px;
			overflow: hidden;
		}
	}

	p.time {
		font-size: 16px;
		font-weight: 300;
		color: #a2a3a3;
		margin-bottom: 5px;
	}

	h1.post-title {
		font-size: 30px;
		margin-bottom: 30px;
		@include media-breakpoint-up(lg) {
			font-size: 52px;
		}
	}

	.post-social {
		margin-bottom: 30px;
	}

	.lead {
		margin-bottom: 50px;
	}
	.post-via {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 16px;
		margin-bottom: 30px;
		justify-content: flex-end;
		color: #fff;

		svg {
			width: 100px;
			height: auto;
			padding-top: 3px;

			.bottom-bar {
				display: none;
			}
		}
	}
}

.post-image {
	margin-bottom: 30px;
	display: flex;
	justify-content: center;

	> a {
		display: block;
		position: relative;

		&:hover, &:focus {
			&:after {
				content: '';
				display: inline-block;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				border: 3px solid var(--bs-primary);
				z-index: 10;
			}
		}

		.image-count {
			position: absolute;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, .9);
			padding: 20px;
			z-index: 5;
			color: #fff;
			display: flex;
			align-items: center;
			font-size: 20px;

			i {
				margin-right: 8px;
				font-size: 24px;
			}

			strong {
				font-weight: 300;
			}
		}
	}

	.caption {
		display: block;
		position: relative;
		padding: 10px 20px;
		color: #7c7c7c;
		font-size: 16px;
		font-style: italic;
		line-height: 26px;

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-top: 8px solid transparent;
			border-left: 16px solid var(--bs-primary);
			border-bottom: 8px solid transparent;
			position: absolute;
			left: 0;
			top: 15px;
		}
	}
}

.post-like-page {
	margin-bottom: 30px;

	h5 {
		border-left: 15px solid var(--bs-primary);
		font-size: 22px;
		font-weight: bold;
		padding: 15px 10px;
		margin: 0 0 20px;
		text-transform: uppercase;
	}
}

ul.tags {
	margin: 0 0 20px;
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	a {
		color: var(--bs-body-color);

		span {
			color: var(--bs-primary);
		}

		&:hover, &:focus, &:active {
			color: var(--bs-primary);

			span {
				color: var(--bs-body-color);
			}
		}
	}
}

.related-posts,
.related-posts-slider {
	background: #fafafa;
	padding: 15px;
	margin-bottom: 20px;

	h5 {
		display: inline-block;
		padding: 8px 10px;
		margin: 0 0 15px;
		background: var(--bs-primary);
		color: #fff;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: normal;
	}
}
.related-posts-slider {
	position: relative;
}

.related-posts article,
.related-posts-slider article,
.recommended-posts article {

	margin-bottom: 20px;
	position: relative;

	h2 {
		margin: 15px 0 0;
		font-size: 15px;
		font-weight: normal;
		line-height: 18px;
		height: 54px;
		overflow: hidden;
	}

	a {
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	.img {
		position: relative;

		img {
			width: 100%;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 0;
			left: 0;
			bottom: 0;
			background: var(--bs-primary);
			-webkit-transition: height 0.25s ease-in-out;
			-o-transition: height 0.25s ease-in-out;
			transition: height 0.25s ease-in-out;
		}
	}

	&:hover {
		.img:after {
			height: 10px;
		}
	}
}

.article-meta {
	margin-bottom: 30px;

	p {
		border-left: 5px solid var(--bs-primary);
		padding-left: 6px;
		font-size: 15px;
		font-weight: 300;
	}
}

.recommended-posts {
	h3 {
		border-bottom: 4px solid var(--bs-primary);
		font-size: 18px;
		text-transform: uppercase;
		padding: 5px;
		margin: 0 0 30px;
	}

	.reload {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 80px;
		background: var(--bs-primary);
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--bs-white);
		border: none;
		text-transform: uppercase;
	}
}

.recommended-posts-list {
	position: relative;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg) {
		padding-right: 90px;
	}

	.row {
		margin: 0 -5px;

		> div {
			padding: 0 5px;
		}
	}

	article {
		margin-bottom: 20px;
	}
}

blockquote:not(.tiktok-embed) {
	border-left: 0 none;
	border-top: 1px dashed #a2a3a3;
	border-bottom: 1px dashed #a2a3a3;
	padding: 50px 10px 40px 10px;
	font-size: 27px;
	font-weight: 300;
	font-style: italic;
	position: relative;
	text-align: center;
	margin: 50px 0 40px;

	@include media-breakpoint-up(lg) {
		line-height: 40px;
		font-size: 37px;
	}

	&:before {
		content: '';
		display: block;
		width: 78px;
		height: 78px;
		background: url(/pics/quote.png) 0 0 no-repeat;
		position: absolute;
		left: 50%;
		top: -39px;
		margin-left: -39px;
	}

	&:after {
		content: '';
		display: block;
		width: 58px;
		height: 58px;
		background: url(/pics/quote.png) -78px 0 no-repeat;
		position: absolute;
		left: 50%;
		bottom: -29px;
		margin-left: -29px;
	}
}

section.tag {
	margin-top: -40px;

	header {
		padding: 30px 0;
		text-align: center;
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.has-image {
			padding: 150px 0;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.tag,
		.result-count {
			background: rgba(0, 0, 0, 0.6);
			padding: 5px 10px;
			color: #fff;
		}

		.tag {
			font-size: 50px;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 18px 25px;
			margin-bottom: 10px;

			span > span {
				color: var(--bs-primary)
			}
		}
	}
}

.lightbox-wrapper {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 20000;
	background: rgba(0, 0, 0, 0.9);
}

.lightbox-content {
	height: 100%;
}

.lightbox-header {
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;
	background: #313439;
	z-index: 150;

	a {
		color: $white;
		font-size: 26px;
		display: flex;
		height: 100%;
		align-items: center;
		padding: 0 10px;
	}
}

.lightbox-logo {
	position: absolute;
	left: 0;
	top: 0;

	.bottom-bar {
		display: none !important;
	}
}

.lightbox-body {
	position: relative;
	height: calc(100% - 50px);
	width: 100%;
	overflow: hidden;
}

.lightbox-sidebar {
	background: #000;
	max-width: 300px;
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all 0.25s ease;
	padding-top: 15px;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
}

.lightbox-viewport {
	transition: all 0.25s ease;
	position: absolute;
	left: 300px;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 30;

	.recommended-posts {
		width: 80%;
	}

	.carousel {
		height: 100%;
		margin: 0;
	}
	.carousel__slide {
		height: calc(100vh - 50px)
	}
}

.lightbox-sidebar-actions {
	position: absolute;
	left: 330px;
	top: 30px;
	z-index: 150;
	display: flex;
	gap: 30px;

	a {
		display: flex;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 4px solid #fff;
		color: $white;
		font-size: 22px;
		justify-content: center;
		align-items: center;

		&:active, &:hover {
			color: var(--bs-primary);
		}
	}
}

.lightbox-title,
.lightbox-date,
.lightbox-image-count {
	color: #cacaca;
	padding: 25px 30px 0;
	text-align: center;
	font-size: 16px;
	font-weight: 300;
	width: 100%;
}

.lightbox-title {
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
}

.lightbox-image-count {
	padding-top: 5px;
}

html.overlay-open,
body.overlay-open {
	overflow: hidden;
}

.lightbox-navigation {
	margin-top: auto;
	width: 100%;
	max-width: 100%;
	padding: 0 15px;
}

.lightbox-navigation-buttons {
	padding: 15px 0;
	display: flex;
	justify-content: space-between;

	a {
		width: 40px;
		height: 40px;
		background: $white;
		color: $black;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;

		&:hover, &:active {
			color: $white;
			background: var(--bs-primary);
		}

		&.disabled {
			opacity: 0.35;
			cursor: auto;
			pointer-events: none;
		}
	}
}

.lightbox-navigation-slide {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	width: 270px;

	a {
		position: relative;
		display: block;

		&:after {
			border: 3px solid var(--bs-primary);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			content: '';
			opacity: 0;
			-webkit-transition: all 0.25s ease;
			-moz-transition: all 0.25s ease;
			-ms-transition: all 0.25s ease;
			-o-transition: all 0.25s ease;
			transition: all 0.25s ease;
		}

		&.active {
			&:after {
				opacity: 1;
			}
		}
	}
}

.lightbox-slide {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 50px);
	width: calc(100vw - 300px);
	transition: all 0.25s ease;

	img {
		pointer-events: none;
	}
}

.lightbox-body.sidebar-hidden {
	.lightbox-sidebar {
		left: -300px;
	}

	.lightbox-viewport {
		left: 0;
	}

	.lightbox-slide {
		width: calc(100vw);
		transition: all 0.25s ease;
	}
}

.lightbox-viewport-prev,
.lightbox-viewport-next {
	position: absolute;
	z-index: 120;
	top: 50%;
	display: flex;
	width: 70px;
	height: 70px;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	background: $black;
	color: $white;
	transform: translate(0, -50%);
	transition: all 0.3s ease;

	&:hover, &:active {
		background: var(--bs-primary);
		color: $white;
	}

	&.disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
}

.lightbox-viewport-prev {
	left: -80px;
}

.lightbox-viewport-next {
	right: -80px;
}

.lightbox-viewport:hover {
	.lightbox-viewport-prev {
		left: 30px;
	}

	.lightbox-viewport-next {
		right: 30px;
	}
}

.poll {
	&.loading {
		&:before {
			margin: 0;
		}
	}

	.poll-content {
		min-height: 100px;
	}
}

.poll-content {
	background: #fff;
	box-shadow: 7px 7px 0 rgba(109, 109, 109, 0.15);
	padding: 15px 15px 15px 40px;
	position: relative;

	h3 {
		padding: 0 0 0 60px;
		margin: 0 0 15px;
		font-size: 25px;
	}

	&:before {
		content: '?';
		display: inline-block;
		width: 45px;
		height: 45px;
		line-height: 45px;
		text-align: center;
		background: var(--bs-primary);
		color: #fff;
		font-weight: bold;
		font-size: 30px;
		position: absolute;
		left: 15px;
		top: -15px;
	}

	.result {
		margin-bottom: 20px;
		line-height: 24px;
		position: relative;
		padding: 0;
		border: 0 none;

		.percent {
			width: 60px;
			height: 100%;
			line-height: 60px;
			background: var(--bs-primary);
			text-align: center;
			color: #fff;
			font-weight: bold;
			position: absolute;
			left: 0;
			top: 0;
			font-size: 14px;

			strong {
				font-size: 23px;
			}
		}

		.text {
			border-style: solid;
			border-width: 3px 3px 3px 0;
			border-color: var(--bs-body-bg);
			margin-left: 60px;

			strong {
				display: block;
				padding: 15px;
			}

			.bar {
				height: 3px;
				background: var(--bs-primary);
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.count {
				position: absolute;
				right: 10px;
				bottom: 6px;
				color: #8f9193;
				font-size: 14px;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.reading-progress {
	margin-top: -40px;
	margin-bottom: 40px;
	width: 100%;
	background: $body-color;
	top: 0;
	position: sticky;
	z-index: 4000;
	padding: 2px;

	div {
		height: 10px;
		background: var(--bs-primary);
	}

	@include media-breakpoint-down(md){
		position: fixed;
		margin: 0;
		left: 0;
        top: auto;
		bottom: 0;
	}
}

.answer-form {
	display: flex;
	gap: 20px;
	flex-direction: column;

	.btn {
		border: 3px solid var(--bs-body-bg);
		padding: 9px 15px 9px 55px;
		position: relative;
		border-radius: 0;
		text-align: left;
		font-size: 22px;

		&:after {
			content: '';
			display: inline-block;
			width: 23px;
			height: 23px;
			border: 3px solid $gray-800;
			background: #fff;
			position: absolute;
			left: 18px;
			top: 20px;
			border-radius: 23px;
		}

		&.selected {
			background: var(--bs-primary);
			color: #fff;
			&:after {
				background: $gray-800;
			}
		}

		&:hover {
			background: var(--bs-primary);
			color: #fff;
		}
	}

	.answer {
		border: 3px solid var(--bs-body-bg);
		padding: 9px 15px 9px 55px;
		position: relative;
		border-radius: 0;
		text-align: left;
		font-size: 22px;

		i {
			position: absolute;
			left: 18px;
			top: 17px;
		}

		&.right {
			border-color: $success;

			i {
				color: $success;
			}
		}
	}
}

.question {
	background: #fff;
	padding: 15px;
	margin-bottom: 45px;
	box-shadow: 7px 7px 0 rgba(109, 109, 109, 0.15);
}

.question-text {
	padding-left: 60px;
	font-weight: bold;
	font-size: 25px;
	position: relative;
	margin-bottom: 1rem;

	strong {
		font-size: 37px;
		line-height: 37px;
		position: absolute;
		left: 0;
		top: 0;
	}
}
.game-winners {
	padding-left: 60px;
	position: relative;
	font-weight: 300;
	i {
		position: absolute;
		left: 0;
		top: -6px;
		color: $warning;
		width: 45px;
		height: 45px;
		text-align: center;
		font-size: 200%;
	}
}
.post-search-field--wrapper {
	position: relative;
	margin-left: auto;

	&.post-search-field--focus,
    &:hover{
		.post-search-field--input {
			background: $gray-800;

			input, i {
				color: #fff;
			}
		}
        .post-search-field--input-wrapper {
            width: auto;
        }
	}
}

@include media-breakpoint-up(lg) {
    .post-search-field--input-wrapper {
        overflow: hidden;
        width: 40px;
        transition: all 0.5s ease;
    }
}
.post-search-field--input {
	display: flex;
	align-items: center;
    justify-content: flex-end;
	border-bottom: 3px solid $gray-800;
	padding: 6px 12px;

	input {
		border: 0 none !important;
		background: transparent;
		color: $gray-800;
		width: 300px;
		box-shadow: none !important;
		padding: 6px 12px;
		outline: none;

		&:focus,
		&:focus-visible {
			box-shadow: none;
		}
	}

	i {
		color: $gray-800;
	}
}
.post-search-field--dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	background: $gray-800;
	padding: 10px;
	color: #fff;
	z-index: 5000;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}
.post-search-field--no-results {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}
.post-search-field--result {
	font-size: 14px;
	position: relative;
	padding: 10px;
	background: #1F2224;
	width: 100%;

	.date {
		font-size: 12px;
	}
	h3 {
		font-size: 14px;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:hover {
		background: var(--bs-primary);

	}

	a {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
}
.post-search-field--mobile {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3000;
	background: rgba(0,0,0,.5);
	overflow-y: scroll;
	padding: 82px 15px 15px;

	.post-search-field--input {
		background: $gray-800;

		input, i {
			color: #fff;
		}
		input {
			width: 100%;
		}
	}
}



.game-cover {
	border-top: 12px solid #e73d3d;
	position: relative;
	margin: 40px 0;
}
.game-cover--title {
	position: absolute;
	display: block;
	left: 20px;
	top: -34px;
	height: 34px;
	line-height: 34px;
	padding: 0 15px;
	background: #e73d3d;
	color: #fff;
	font-size: 23px;
	text-transform: uppercase;
	vertical-align: top;
}
.game-cover--image {
	background-position: center center;
	background-size: cover;
}
.game-cover--overlay {
	display: flex;
	height: 550px;
	flex-direction: column;
	width: 100%;
	padding: 15px;
	color: $white;
	background: rgba(0, 0, 0, 0.5);

	@include media-breakpoint-up(lg) {
		width: 40%;
	}

	h1 {
		padding: 5px;
		margin: 0;
		color: $white;
	}

	.info-box {
		background: #e73d3d;
		margin-top: auto;
		font-size: 20px;
		line-height: 30px;
		padding: 15px;

		i {
			margin-right: 5px;
		}
	}
}

article.game {
	margin-bottom: 30px;
	position: relative;
	padding: 15px 15px 15px 30px;
	color: var(--bs-body-color);
	font-size: 24px;

	&:hover, &:active, &:focus {
		background: #e0e0e0;
		border-left: 15px solid var(--bs-primary);
		padding-left: 15px;
		text-decoration: none;
	}
	a {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

	}
	i.fa-angle-double-right {
		color: var(--bs-primary);
	}
	.info {
		display: inline-block;
		margin-right: 20px;
		font-weight: 300;
		i {
			font-weight: normal;
			margin-right: 5px;
		}
	}
	h2 {
		margin: 0 0 5px;
		font-size: 37px;
	}
}

article.game-closed {
	font-size: 18px;
	border-bottom: 1px solid var(--bs-primary);
	padding-bottom: 5px;
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}

	h2 {
		font-size: 24px;
		margin: 0 0 30px;
		padding-left: 15px;
	}
	p {
		padding-left: 15px;
	}

	a {
		display: block;
		background: #e0e0e0;
		padding: 4px 15px;
		color: var(--bs-body-color);
		font-size: 14px;
		text-transform: uppercase;
		font-weight: bold;
		line-height: 26px;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: var(--bs-primary);
		}
		i {
			color: var(--bs-primary);
			margin-right: 5px;
			font-size: 26px;
		}
	}
}
.anouncement {
	background: #ebebeb;
	width: 100%;
	box-shadow: 0 0 10px rgba(109, 109, 109, 0.4);
	margin-bottom: 30px;
	border: 0 none !important;
}
.anouncement-title {
	padding: 10px 28px;
	font-size: 19px;
	line-height: 24px;
	font-weight: bold;
	border-bottom: 1px solid #3888ff !important;
}
.anouncement-body {
	padding: 15px 28px;
	border: 0 none !important;
	font-size: 15px;
	line-height: 20px;
}
.anouncement-footer {
	padding: 0 28px 15px 28px;
	font-size: 14px;
	font-style: italic;
}
.emphasized-text-section {
	h3 {
		margin: 0;
		border-left: 7px solid #3888ff;
		font-size: 19px;
		line-height: 30px;
		text-transform: uppercase;
		font-weight: bold;
		padding: 0 10px 0 15px;
		border-bottom: 0 none;

		@include media-breakpoint-down(sm){
			font-size: 29px;
			line-height: 32px;
		}
	}
}
.post-slider-item {
	position: relative;

	a {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
}
.post-slider-item--content {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 50px 30px 30px;
	background: rgb(0,0,0);
	background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);

	h2 {
		margin: 0;
		text-align: left;
		color: $white;
		font-size: 28px;
	}
}
.post-slider {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
}
.post-slider-indicator,
.related-posts-slider-indicator {
	position: absolute;
	right: 20px;
	top: 20px;
	padding: 2px 15px;
	border-radius: 20px;
	background: rgba(0,0,0,.5);
	border: 1px solid #fff;
	color: #fff;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
}
.post-slider:hover {

	.post-slider-navigation-prev {
		transform: translate(0, -50%);
	}
	.post-slider-navigation-next {
		transform: translate(0, -50%);
	}
}
.post-slider-navigation {
	position: absolute;
	top: 50%;
	background: rgba(0,0,0,0.4);
	color: #fff;
	border: 0 none;
	padding: 20px 15px;
	cursor: pointer;
	transform: translate(0, -50%);
	transition: all 0.4s ease;
	font-size: 24px;

	&:hover {
		color: var(--bs-primary)
	}
}
.post-slider-navigation-prev {
	transform: translate(-110%, -50%);
	left: 0;
}
.post-slider-navigation-next {
	transform: translate(110%, -50%);
	right: 0;
}
.footer-supporters {
	@include media-breakpoint-down(lg){
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	img {
		max-width: 200px;
	}
}
.post-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: masonry;
	gap: 30px;

	@include media-breakpoint-down(md){
		grid-template-columns: repeat(1, 1fr);
	}
}
.container > .post-list {
	grid-template-columns: repeat(3, 1fr);

	@include media-breakpoint-down(md){
		grid-template-columns: repeat(1, 1fr);
	}
}
section.carousel {
	margin: 0;
	border-radius: 10px;
	overflow: hidden;
}
.video-image {
	position: relative;

	i {
		position: absolute;
		left: 20px;
		top: 20px;
		font-size: 22px;
		color: var(--bs-primary);

	}
}
.popup-banner {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 50000;
	background: rgba(0, 0, 0, 0.9);
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.popup-banner--header {
	margin-bottom: 10px;
	display: flex;
	justify-content: right;
}
.game-box-item {
	position: relative;
	border-bottom: 10px solid transparent;
	cursor: pointer;

	h4 {
		margin: 0 0 15px;
	}

	&:hover {
		background: rgba(0,0,0,.1);
		border-bottom-color: var(--bs-primary)
	}

	a {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
}

.post-promotion {
    background: var(--bs-success);
    padding: 10px 15px;
    color: var(--bs-white);
    margin-bottom: 10px;
}
@include media-breakpoint-down(md){
    .tns-controls {
        display: none;
    }
}
.nsfw-modal-backdrop.show {
    z-index: 5000;
    opacity: .9;
}
.nsfw-modal {
    z-index: 5001;

    .modal-dialog {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) !important;
        margin: 0 !important;
    }
}
.image-loading {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
}
