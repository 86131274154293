$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1140px
) !default;

$font-family-sans-serif:    'Roboto Slab', serif;

$success:					#25cf75;
$info:						#5bc0de;
$warning:					#eccc1e;
$danger:					#e73d3d;

$body-bg:                   #f0f0f0;
$body-color:                #313539;
$grid-gutter-width:         1.875rem;
$gray-800:                  #313539;
$headings-font-weight:      700;

$input-bg: 					#e6e6e6;
$input-border-color: 		#e6e6e6;
$input-focus-box-shadow: 	none;
$input-border-radius: 		0;
$input-btn-line-height: 	2;
$input-btn-font-size: 		1.4rem;

$body-bg-dark:				#383838;